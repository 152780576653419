<template>
  <v-container id="for-seo">
    <div>
      <v-breadcrumbs
        :items="breadcrumbs"
        :style="{ opacity: trainingName ? 1 : 0 }"
        class="ml-0 pl-0"
        divider="<"
        large
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <h2 :style="{ opacity: !trainingStateLoading ? 1 : 0 }" class="mb-2">{{ trainingName }}</h2>
    <div class="training-list-table__actions d-flex align-center">
      <v-btn
        :disabled="!canAdd"
        class="mr-4"
        color="primary"
        depressed
        large
        @click="openDialog('section')"
      >
        {{ $t('admin.training.trainingList.table.button.addSection') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <v-btn
        :disabled="!canAdd"
        class="mr-4"
        color="primary"
        depressed
        large
        @click="redirectListParticipantsPage"
      >
        {{ $t('admin.training.trainingList.table.button.listOfParticipants') }}
      </v-btn>

      <v-text-field
        v-model="search"
        :label="$t('globals.search')"
        class="actions__search"
        dense
        hide-details
        outlined
        prepend-inner-icon="mdi-magnify"
      />
    </div>

    <base-list
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :items="tableItem"
      :itemsPerPage="10"
      :loading="isLoadingOrUploading"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
      :search="search"
      backgroundColor="var(--v-adminBackground-base)"
      class="mt-4 ml-8"
    >
      <template #body="data">
        <Draggable v-model="tableItem" tag="tbody">
          <tr v-for="(item, index) in data.items" :key="`row.${index}`" class="">
            <template v-for="(col, idx) in data.headers">
              <td :key="`col.${index}.${idx}`" :class="{ 'drag-id': col.value == 'id' }">
                <div
                  v-if="col.value == 'name'"
                  class="clickable"
                  @click="redirectListSectionPage(item.id)"
                >
                  <div>{{ item.name }}</div>
                </div>
                <span v-else-if="col.value == 'id'" class="float-number">{{ index + 1 }}</span>
                <div v-else-if="col.value == 'public'" class="d-flex justify-end align-center">
                  <div class="public-section-status" style="white-space: break-spaces">
                    <span v-if="!item.published" style="white-space: break-spaces">
                      {{ formatDate(item.published) }}
                    </span>
                    <v-btn
                      v-else
                      style="white-space: break-spaces; background-color: unset; box-shadow: unset"
                      @click="openDialog('changePublishDate', item)"
                    >
                      {{ formatDate(item.published) }}
                    </v-btn>
                  </div>
                  <v-btn class="xl:ml-8" color="secondary" icon>
                    <v-icon
                      v-if="
                        item.published && new Date(item.published).getTime() <= new Date().getTime()
                      "
                      :title="$t('admin.users.list.deactivate')"
                      class="icon-flipped"
                      color="primary"
                      size="40"
                      @click="openDialog('unpublish', item)"
                    >
                      mdi-toggle-switch-off
                    </v-icon>
                    <v-icon
                      v-else-if="new Date(item.published).getTime() > new Date().getTime()"
                      :title="$t('admin.users.list.deactivate')"
                      class="icon-flipped"
                      size="40"
                      @click="openDialog('unpublish', item)"
                    >
                      mdi-toggle-switch
                    </v-icon>
                    <v-icon
                      v-else
                      :title="$t('admin.users.list.activate')"
                      class="icon-flipped"
                      size="40"
                      @click="openDialog('public', item)"
                    >
                      mdi-toggle-switch
                    </v-icon>
                  </v-btn>
                  <v-icon class="ml-8 clickable" size="25"> mdi-menu</v-icon>
                </div>
                <div v-else-if="col.value == 'actions'" class="d-flex justify-sm-space-around">
                  <v-btn icon @click="openDialog('section', item)">
                    <v-icon :title="$t('globals.edit')">mdi-cog-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="openDialog('delete', item)">
                    <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div v-else>{{ item[col.value] }}</div>
              </td>
            </template>
          </tr>
        </Draggable>
      </template>
    </base-list>

    <v-dialog
      content-class="v-dialog--overlay"
      v-if="dialog.section"
      v-model="dialog.section"
      width="800"
      @click:outside="closeDialog('section')"
    >
      <v-card>
        <v-card-title>
          {{ $t(`admin.training.trainingList.table.button.addSection`) }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="inputDialogSection"
                :label="$t('admin.training.trainingList.popup.tab.name')"
                :persistent-placeholder="true"
                :rules="nameRules"
                hide-details="auto"
                placeholder="-"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="actions">
          <v-btn color="primary" data-test-id="cancel" outlined @click="closeDialog('section')">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn
            :disabled="!inputDialogSection"
            color="primary"
            data-test-id="add"
            @click="onDialogSubmit"
          >
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirmation
      :content="$t('admin.training.trainingList.popup.delete.deleteConfirmationDialog.section')"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialog.delete"
      @cancel="closeDialog('delete')"
      @confirm="confirmDeleteDialog()"
    />
    <dialog-confirmation
      :content="'Are you sure you wish to unpublish this section ?'"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialog.unpublish"
      @cancel="closeDialog('unpublish')"
      @confirm="unpublishSection()"
    />
    <dialog-confirmation
      :content="$t('trainings.doYouWantPublishIt')"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialog.publicWithNoLesson"
      @cancel="closeDialog('publicWithNoLesson')"
      @confirm="openDialog('publicWithLesson')"
    />
    <v-dialog
      content-class="v-dialog--overlay"
      v-if="dialog.publicWithLesson"
      v-model="dialog.publicWithLesson"
      width="350"
      @click:outside="closeDialog('publicWithLesson')"
    >
      <PublicFormationItem @cancel="closeDialog('publicWithLesson')" @submit="onClickPublic" />
    </v-dialog>
    <v-dialog
      content-class="v-dialog--overlay"
      v-if="dialog.changePublishDate"
      v-model="dialog.changePublishDate"
      width="350"
      @click:outside="closeDialog('changePublishDate')"
    >
      <ChangePublishDate
        :curItem="this.curItem"
        @cancel="closeDialog('changePublishDate')"
        @submit="onClickPublic"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

import { ADMIN_USER_MODULE } from '@/stores/umanize-admin/actions/user/admin-user.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import BaseList from '@/components/base-list/BaseList.vue';
import i18n from '@/i18n';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import VALIDATORS from '@/helpers/forms/validators.helper';
import {
  ADD_TRAINING_SECTION,
  DELETE_TRAINING_SECTION,
  GET_ALL_TRAININGS_SECTION_ADMIN,
  PUBLISH_TRAINING_SECTION,
  TRAINING_SECTION_ADMIN_MODULE,
  UNPUBLISH_TRAINING_SECTION,
  UPDATE_TRAINING_SECTION,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import {
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import ChangePublishDate from '@/views/admin/trainings/training-list/training-formation-seo/dialog/ChangePublishDate.vue';
import PublicFormationItem from './dialog/PublicFormationItem.vue';

export default {
  name: 'TrainingFormationSEO',
  components: {
    ChangePublishDate,
    BaseList,
    DialogConfirmation,
    Draggable,
    PublicFormationItem,
  },
  data: () => ({
    canAdd: true,
    nameRules: VALIDATORS.REQUIRED.NAME,
    curItem: null,
    trainingName: false,
    breadcrumbs: [
      {
        text: i18n.t('admin.training.trainingList.title.listOfTraining'),
        disabled: false,
      },
      {
        text: 'Training',
        disabled: true,
      },
    ],
    tableItem: [],
    dialog: {
      section: false,
      delete: false,
      public: false,
      unpublish: false,
      changePublishDate: false,
      publicWithNoLesson: false,
      publicWithLesson: false,
    },
    inputDialogSection: '',
    search: '',
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event', 'usersAreLoading', 'eventUsers']),
    ...mapGetters(ADMIN_USER_MODULE, ['usersIsUploading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, ['allTrainingSectionData']),
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingData', 'trainingStateLoading']),
    headers() {
      return [
        {
          align: 'start',
          value: 'id',
          width: '16px',
        },
        {
          text: this.$t('admin.training.trainingList.table.headers.name'),
          align: 'start',
          value: 'name',
          width: '175px',
        },
        {
          text: this.$t('admin.training.trainingList.table.headers.publish'),
          value: 'public',
          align: 'center',
          width: '100',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '50px',
        },
      ];
    },
    isLoadingOrUploading() {
      return false;
    },
  },
  methods: {
    ...mapActions(ADMIN_EVENT_MODULE, [FETCH_EVENT_USERS]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [
      GET_ALL_TRAININGS_SECTION_ADMIN,
      ADD_TRAINING_SECTION,
      UPDATE_TRAINING_SECTION,
      DELETE_TRAINING_SECTION,
      PUBLISH_TRAINING_SECTION,
      UNPUBLISH_TRAINING_SECTION,
    ]),
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_TRAINING]),
    onSearchChange(search) {
      this.search = search;
    },
    formatDate(date) {
      if (!date) return '            - -';
      return `${new Date(date).toISOString().slice(0, 10)} ${new Date(date).getHours()}:${new Date(
        date,
      ).getMinutes()}`;
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    async openDialog(name, item) {
      if (name === 'section') {
        this.inputDialogSection = item?.name;
      }
      if (item) {
        this.curItem = item;
      }
      if (name === 'public') {
        const { eventId, trainingId, id } = item;
        const data = await trainingLessonService.getAll(eventId, trainingId, id);
        if (!data.length) {
          this.dialog.publicWithNoLesson = true;
        } else this.dialog.publicWithLesson = true;
      }
      if (name === 'publicWithLesson') {
        this.dialog.publicWithNoLesson = false;
      }

      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.inputDialogSection = '';
      this.dialog[name] = false;
      this.curItem = null;
    },
    async onClickPublic(data) {
      await this[PUBLISH_TRAINING_SECTION]({
        eventId: this.$route.params.eventId,
        trainingId: this.$route.params.idTraining,
        sectionId: this.curItem.id,
        published: data,
      });
      this.curItem = null;
      await this.loadTrainingSectionsData();
      this.closeDialog('public');
      this.closeDialog('publicWithLesson');
    },
    async unpublishSection() {
      await this[UNPUBLISH_TRAINING_SECTION]({
        eventId: this.$route.params.eventId,
        trainingId: this.$route.params.idTraining,
        sectionId: this.curItem.id,
      });
      this.curItem = null;
      await this.loadTrainingSectionsData();
      this.closeDialog('unpublish');
      this.curItem = null;
    },
    async confirmDeleteDialog() {
      await this[DELETE_TRAINING_SECTION]({
        eventId: this.$route.params.eventId,
        trainingId: this.$route.params.idTraining,
        section: this.curItem,
      });
      this.closeDialog('delete');
    },
    async onDialogSubmit() {
      if (this.curItem) {
        await this[UPDATE_TRAINING_SECTION]({
          eventId: this.$route.params.eventId,
          trainingId: this.$route.params.idTraining,
          section: {
            id: this.curItem.id,
            name: this.inputDialogSection,
          },
        });
      } else {
        await this[ADD_TRAINING_SECTION]({
          eventId: this.$route.params.eventId,
          trainingId: this.$route.params.idTraining,
          section: {
            name: this.inputDialogSection,
            priority: 0,
          },
        });

        const listUpdate = this.tableItem.map((ele, index) => ({
          ...ele,
          priority: index + 1,
        }));

        const listPromise = listUpdate.map((ele) =>
          this[UPDATE_TRAINING_SECTION]({
            eventId: ele.eventId,
            trainingId: ele.trainingId,
            section: ele,
          }),
        );
        await Promise.all(listPromise);
      }

      await this.loadTrainingSectionsData();
      this.closeDialog('section');
    },
    redirectListParticipantsPage() {
      this.$router.push({
        name: 'TrainingFormationSEOListParticipants',
        params: {
          eventId: this.$route.params.eventId,
          idTraining: this.$route.params.idTraining,
        },
      });
    },
    redirectListSectionPage(isSection) {
      this.$router.push({
        name: 'TrainingFormationSEOListSection',
        params: {
          eventId: this.$route.params.eventId,
          idTraining: this.$route.params.idTraining,
          idSection: isSection,
        },
      });
    },
    async loadTrainingSectionsData() {
      await this[GET_ALL_TRAININGS_SECTION_ADMIN]({
        eventId: this.$route.params.eventId,
        trainingId: this.$route.params.idTraining,
      });
    },
  },
  async mounted() {
    const { idTraining, eventId } = this.$route.params;
    await this[GET_TRAINING]({ eventId, trainingId: idTraining });
    this.trainingName = this.trainingData?.name ?? '';
    this.breadcrumbs.at(-1).text = this.trainingName;
    this.breadcrumbs.at(-2).href = `/events/${eventId}/admin/training/`;
    await this.loadTrainingSectionsData();
  },
  watch: {
    allTrainingSectionData(newVal) {
      this.tableItem = newVal;
    },
    async tableItem(newVal) {
      const newList = newVal.map((ele, index) => ({
        ...ele,
        priority: index,
      }));
      const listUpdate = newList.filter((ele) => {
        const oldItem = this.allTrainingSectionData.find((itemEle) => itemEle.id === ele.id);
        if (oldItem.priority !== ele.priority) return true;
        return false;
      });
      const listPromise = listUpdate.map((ele) =>
        this[UPDATE_TRAINING_SECTION]({
          eventId: ele.eventId,
          trainingId: ele.trainingId,
          section: ele,
        }),
      );
      await Promise.all(listPromise);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();
#for-seo {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  .clickable {
    cursor: pointer;
  }
}

::v-deep .v-data-table {
  th {
    padding-left: 0px !important;
  }

  td:nth-child(2) {
    padding-left: 0px !important;
  }

  .drag-id {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

.drag-id {
  position: relative;

  .float-number {
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.public-section-status {
  width: 100%;
  @media screen and (max-width: 1279px) {
    min-width: 100%;
  }
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
